@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-sarabunFont;
    @apply text-textSize;
  }
}

.active {
  @apply text-primary
}

.firstAnimate {
  animation: transitionYIn 0.6s;
}
.secondaryAnimate {
  animation: transitionYIn 1.2s;
}
.thirdAnimate {
  animation: transitionYIn 2s;
}
.fouthAnimate {
  animation: transitionYIn 2.6s;
}
.fifthAnimate {
  animation: transitionYIn 3.2s;
}
@keyframes transitionYIn {
  from {
    opacity: 0;
    transform: translateY(250px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.bannerYAnimate {
  animation: transitionBannerIn 1.2s;
}
@keyframes transitionBannerIn {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to{
    opacity: 1;
    transform: translateY(0);
  }
}

.buttonXAnimate {
  animation: transitionXIn 3s;
}
@keyframes transitionXIn {
  from {
    opacity: 0;
    transition: translateX(250px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.hoverAnimate {
  animation: hover 1s ease-in infinite;
}

@keyframes hover {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(20px);
  }
}

.container{
  transition: box-shadow 0.4s ease-out;
}

.container:hover {
  box-shadow: -1px -1px 8px 0px #c0c0c0, 1px 1px 8px 0px #c0c0c0;
}

